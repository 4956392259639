import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Chocolate = makeShortcode("Chocolate");
const Flex = makeShortcode("Flex");
const Icon = makeShortcode("Icon");
const Button = makeShortcode("Button");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "research",
      "style": {
        "position": "relative"
      }
    }}>{`Research`}<a parentName="h1" {...{
        "href": "#research",
        "aria-label": "research permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`This page is intended for all of the great and intersting resources our community has gathered which are not specifically tools, but which are worthy of your attention.`}</p>
    <h2 {...{
      "id": "videos",
      "style": {
        "position": "relative"
      }
    }}>{`Videos`}<a parentName="h2" {...{
        "href": "#videos",
        "aria-label": "videos permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Video is a `}<a parentName="p" {...{
        "href": "/learn/module-3/remember/#extending-our-mnemonics"
      }}>{`more effective mnemonic medium than text`}</a>{`. Two non-crypto channels that we feel do the best job of illustrating why are `}<a parentName="p" {...{
        "href": "https://www.youtube.com/c/3blue1brown/featured"
      }}>{`3blue1brown`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.youtube.com/channel/UCs7nPQIEba0T3tGOWWsZpJQ"
      }}>{`Like Stories of Old`}</a>{`.`}</p>
    <p>{`The YouTube channels we link to below are not necessarily research in the traditional sense. They are much more about sense-making, which is itself a `}<a parentName="p" {...{
        "href": "https://joelightfoot.substack.com/p/the-liminal-web-mapping-an-emergent"
      }}>{`fascinating modern phenomena to research`}</a>{`. These channels are included on this page not because of their rigour, but to suggest to you that research may be changing in ways those in academia simply cannot fathom, and that there are ways to research in public (just like coding in public) which can be enormously beneficial, both to you as a creator and to the wider public you serve.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Whiteboard Crypto`}</strong></p>
          <p>{`One of the best YouTube channels for general crypto concepts.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://www.youtube.com/channel/UCsYYksPHiGqXHPoHI-fm5sg" mdxType="Button">
    Watch
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Finematics`}</strong></p>
          <p>{`The other best YouTube channel for general crypto concepts.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://www.youtube.com/c/Finematics/videos" mdxType="Button">
    Watch
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Foundations of Blockchains`}</strong></p>
          <p>{`Likely the best place to get to grips with the low level fundamentals, taught by Tim Roughgarden.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://www.youtube.com/watch?v=KNJGPI0fuFA&list=PLEGCF-WLh2RLOHv_xUGLqRts_9JxrckiA" mdxType="Button">
    Watch
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "reading",
      "style": {
        "position": "relative"
      }
    }}>{`Reading`}<a parentName="h2" {...{
        "href": "#reading",
        "aria-label": "reading permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Research often does come down to simply parsing lots of information. This section is your opportunity to get good at that.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`The Short Overview`}</strong></p>
          <p>{`A succinct and insightful list of resources, in chronological order`}</p>
          <Button to="https://danromero.org/crypto-reading/" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Timing
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`The Token Economy`}</strong></p>
          <p>{`One of the OG resources, begun before 2015.`}</p>
          <p><em parentName="p">{`H/T `}<Link to="https://www.reddit.com/r/ethereum/comments/o4unlp/ama_we_are_the_efs_research_team_pt_6_23_june_2021/" mdxType="Link">{`EF Research Team`}</Link></em></p>
          <Button to="https://medium.com/token-kitchen/publishing-token-economy-on-github-under-cc-by-nc-sa-ec569008a5dd" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Dive In
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`The Other Internet`}</strong></p>
          <p>{`A fascinating collaboration between many big minds. Begin with `}<Link to="https://otherinter.net/research/squad-wealth/" mdxType="Link">{`Squad Wealth`}</Link>{`. Complement with:`}</p>
          <Button to="https://otherinter.net/research/positive-sum-worlds/" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Good Publics
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`How to DAO`}</strong></p>
          <p>{`A four-week, better-than-free course on the future of co-operation, created by Stephen Reid.`}</p>
          <Button to="https://docs.google.com/document/d/1jxbb3YkrjAT1TUe6W2yCFUAsXUhdVt5JYoJwmMfykoQ/edit#heading=h.xm43iia8mx4f" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Cooperate
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Web3 Fundamentals`}</strong></p>
          <p>{`A really fantastic course provided for free by some friends of ours.`}</p>
          <p><em parentName="p">{`H/T John`}</em></p>
          <Button to="https://github.com/londonblockchainlabs/web3-fundamentals" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Have fun
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Bitcoin Information`}</strong></p>
          <p>{`Jameson Lopp's Bitcoin Resource lists.`}</p>
          <Button to="https://www.lopp.net/bitcoin-information.html" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Study
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Coinstory`}</strong></p>
          <p>{`A book that maps the history and evolution of cryptocurrencies, written by a Kernel fellow.`}</p>
          <p><em parentName="p">{`H/T David Stancel`}</em></p>
          <Button to="https://coinstory.tech/" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Discover
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Job Resources`}</strong></p>
          <p>{`A great free guide that is still relevant today, we assure you.`}</p>
          <p><em parentName="p">{`H/T Jo-Ann Hamilton`}</em></p>
          <Button to="https://medium.com/coinmonks/free-resources-to-get-a-blockchain-job-in-6-months-or-less-for-non-coders-5ddee72fbc7b" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Guide Yourself
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`WEF Toolkit`}</strong></p>
          <p>{`The ultimate corporate toolkit. Very serious ;)`}</p>
          <p><em parentName="p">{`H/T Gyan`}</em></p>
          <Button to="https://tinyurl.com/wef-blockchain-tools" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Toolkit
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "climate",
      "style": {
        "position": "relative"
      }
    }}>{`Climate`}<a parentName="h2" {...{
        "href": "#climate",
        "aria-label": "climate permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`This section will be fleshed out in blocks yet to come, as it becomes clear where the most valuable contributions and research are happening.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`ReFi Podcast`}</strong>{` `}</p>
          <p>{`Conversations with a changing climate.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://refipodcast.podbean.com/" mdxType="Button">
    Read
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`KlimaDAO on Bankless`}</strong></p>
          <p>{`A wonderful conversation about global coordination.`}</p>
          <Button to="https://youtu.be/uM5XX4AwEuI" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Listen In
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Toucan Protocol`}</strong></p>
          <p>{`Cultivating planetary stewardship.`}</p>
          <Button to="https://mirror.xyz/0x84F5590Ffe54e0f684b845807D036D8C1D18e684/FqIc41yUQF4Jw-ppjJh1x28Wh143D2CjG1fGk3sGzWc" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Read Up
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "security",
      "style": {
        "position": "relative"
      }
    }}>{`Security`}<a parentName="h2" {...{
        "href": "#security",
        "aria-label": "security permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`While there are wonderful tools and informative news sources already listed on the security page of this section, securing code on public, adversarial networks has required - and will continue to require - a great deal of groundbreaking research. Here we present some of the most interesting pieces we have touched on in Kernel.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Smashing Smart Contracts for Fun and Profit`}</strong></p>
          <p>{`The original paper by Bernhard on profiting from fun and the security analysis tool Mythril.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://github.com/b-mueller/smashing-smart-contracts/blob/master/smashing-smart-contracts-1of1.pdf" mdxType="Button">
    Symbolise
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Automated Tooling`}</strong></p>
          <p>{`A fascinating list of security resources from Johan which, despite our aversion to such things, is too good not to include here.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://www.notion.so/Module-2-Automated-Tooling-22b7b1513dd247d685616d55e27b6877" mdxType="Button">
    Mutate
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "zero-knowledge",
      "style": {
        "position": "relative"
      }
    }}>{`Zero Knowledge`}<a parentName="h2" {...{
        "href": "#zero-knowledge",
        "aria-label": "zero knowledge permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Likely the most research-intensive aspect of the cryptographic world as it has applied to blockchains, we present here just a few of the growing number of resources available to help you first understand, and then begin to implement your own, zero-knowledge schemes. `}<a parentName="p" {...{
        "href": "https://youtu.be/OcmvMs4AMbM?t=273"
      }}>{`This short video`}</a>{` is a good place to start if you know nothing about zero knowledge. Take a read through `}<a parentName="p" {...{
        "href": "https://twitter.com/EliBenSasson/status/1578380154476208131"
      }}>{`Eli Ben Sasson's mammoth twitter thread`}</a>{` for another great entry point.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`ZeroKnowledge FM`}</strong></p>
          <p>{`Anna Rose's podcast collects presentations on zero knowledge topics from different teams and should serve as a great entry into more technical research.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://www.zeroknowledge.fm/" mdxType="Button">
    Enjoy
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`zku.one`}</strong>{` `}</p>
          <p>{`A comprehensive learning experience which covers some theory and a lot of practice.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://zku.one/course-schedule" mdxType="Button">
    Read
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Matter Labs`}</strong></p>
          <p>{`This is the "zkSNARKs bible". `}</p>
          <p><em parentName="p">{`H/T Luka`}</em></p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://github.com/matter-labs/awesome-zero-knowledge-proofs" mdxType="Button">
    Discover
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`The Industry Overview`}</strong></p>
          <p>{`An in-depth post from the zcash team, with many wonderful links to further research.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://z.cash/technology/zksnarks/" mdxType="Button">
    Understand
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`The Academic Overview`}</strong></p>
          <p>{`A surprisingly straightforward explanation, with examples. `}<a parentName="p" {...{
              "href": "https://github.com/AztecProtocol/AZTEC/blob/develop/AZTEC.pdf"
            }}>{`AZTEC Protocol’s paper`}</a>{` by Zachary J. Williamson is also relevant here.`}</p>
          <p><em parentName="p">{`H/T Roshan Raghupathy`}</em></p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://arxiv.org/pdf/1906.07221.pdf" mdxType="Button">
    Understand
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`PLONK`}</strong></p>
          <p>{`Enjoy two for the price of one with the PLONK paper by the AZTEC team and `}<Link to="https://vitalik.eth.limo/general/2019/09/22/plonk.html" mdxType="Link">{`Vitalik’s comments`}</Link>{`.`}</p>
          <Button sx={{
            position: 'absolute',
            bottom: '-40px'
          }} to="https://eprint.iacr.org/2019/953.pdf" mdxType="Button">
    Dive In
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h2 {...{
      "id": "spreadsheets",
      "style": {
        "position": "relative"
      }
    }}>{`Spreadsheets`}<a parentName="h2" {...{
        "href": "#spreadsheets",
        "aria-label": "spreadsheets permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`If you have imbibed our distate of Awesome lists, you can likely guess how we feel about spreadsheets. However, there are always exceptions that prove the rule, and here we list just a few of them for specific domains in which you may be interested.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Token Economics Resource List`}</strong></p>
          <p>{`Literally everything you could ever want to know about tokens, and probably lots you don't.`}</p>
          <Button to="https://docs.google.com/spreadsheets/d/1_py70Ic2u91VILJWCA7AMOXVZmmTv-zGo-LPr3PhDvM/edit#gid=2065938169" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Even Deeper
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`All NFT Marketplaces`}</strong></p>
          <p>{`Taken from this `}<Link to="https://github.com/gianni-dalerta/awesome-nft" mdxType="Link">{`awesome list`}</Link>{`.`}</p>
          <Button to="https://docs.google.com/spreadsheets/d/1Idy9TBXcWk6VpbASscXHSORaoaz0YR_arBvKRDu6xOI/edit#gid=0" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Research
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`All Messenger Protocols`}</strong></p>
          <p>{`An insightful spreadsheet for anyone working on messaging.`}</p>
          <p><em parentName="p">{`H/T Dhruv Malik`}</em></p>
          <Button to="https://docs.google.com/spreadsheets/d/1-UlA4-tslROBDS9IqHalWVztqZo7uxlCeKPQ-8uoFOU/edit#gid=0" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Broadcast
          </Button>
        </Flex>
      </Chocolate>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      